
import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { inject } from "mobx-react";

import { Slider, Gallery, Seo, ProductQualities, Spotlights } from "components"
import { PAGE_VIEW_EVENT, TraumwandPageComponent, getLanguageValue, getFluidImageSharp } from "utils";
import { SquidexProductPage, SquidexTexts } from 'generated/gatsbyApiTypes';

export interface QueryData {
  squidexProductPage: SquidexProductPage
  squidexTexts: SquidexTexts
}

@inject("visitorStore")
export default class ProductPage extends TraumwandPageComponent<QueryData> {
  componentWillMount() {
    const data = this.props.data.squidexProductPage;
    const materialId = data.materialId ? data.materialId.iv : undefined;
    if (materialId) {
      this.props.visitorStore.spot(materialId);
    }
    this.props.visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "product"
    });
  }
  render() {
    const data = this.props.data.squidexProductPage;
    const qualities = data.qualities ? getLanguageValue(data.qualities) : [];
    const headerImage = getFluidImageSharp(data.headerImage);
    const examplesHeader = data.examplesHeader ? getLanguageValue(data.examplesHeader) : "";
    const galleryHeader = data.galleryHeader ? getLanguageValue(data.galleryHeader) : "";
    const title = data.title ? getLanguageValue(data.title) : "";
    const materialId = data.materialId ? data.materialId.iv : undefined;

    const { examples, gallery, spotlights } = data;

    return (
      <>
        <Seo subTitle={title} />
        <h1>{title}</h1>
        <section id="intro" className="h-100 flex flex-row-ns flex-column-reverse justify-around">
          <div className="w-100 w-third-ns mt3 mt0-ns self-center">
            <ProductQualities nodes={qualities} />
          </div>
          <div className="w-100 w6-ns">
            <Img fluid={headerImage} />
          </div>
        </section>
        <section id="about">
          <Spotlights nodes={spotlights} />
        </section>
        <section id="examples" className="bt-ns b--light-gray">
          <h3>{examplesHeader}</h3>
          <Slider className="mb3 w-90 center" nodes={examples} clickToEnlargeText={getLanguageValue(this.props.data.squidexTexts.txtClickToEnlarge)} />
        </section>
        <section id="gallery">
          <h3>{galleryHeader}</h3>
          <Gallery nodes={gallery} navigate={this.props.navigate} materialId={materialId} />
        </section>
        <div className="mb4" />
      </>
    )
  }
}

export const query = graphql`
query($id: String!) {
  squidexTexts(identity: {eq: "squidextexts"}) {
    txtClickToEnlarge {
      de
      nl
      en
    }
  }
  squidexProductPage(id: { eq: $id }) {
    title {
      de
      nl
      en
    }
    materialId {
      iv
    }
    qualities {
      de {
        text,
        positive
      }
      nl {
        text,
        positive
      }
      en {
        text,
        positive
      }
    }
    headerImage {
      childImageSharp {
        fluid { 
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    spotlights {
      text {
        de {
          html
        }
        nl {
          html
        }
        en {
          html
        }
      }
      image {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    examplesHeader {
      de
      nl
      en
    }
    examples {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    galleryHeader {
      de
      nl
      en
    }
    gallery {
        name {
        iv
      }
      description {
        iv
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
          original {
            src
            width
            height
          }
        }
      }
    }
  }
}
`
